import React from 'react';
import './LandingStyles.css';
import Button from './Button';

const Landing = () => {
  return (
    <div className='landing'>
      <div className='dark-overlay'>
      <div className='centered'>
        <h1 style={{fontWeight: 'bold'}}>Data Concierge at Your Service <sup>TM</sup></h1>
        <br />
        <p>
          The modern, high-growth organization sees value in access to an
          efficient data concierge service that responds perfectly to your need
          for data solutions that are both impactful and inclusive.
        </p>
        <br />
        <Button buttonText={'Get Access'} />
      </div>
      </div>    </div>
  );
};

export default Landing;
