import React from 'react'
import './SlideLogosStyles.css'

const SlideLogos = () => {
  return (
    <div className='slide-container'>
       <div className='sliding-background'>
        </div>
    </div>  )
}

export default SlideLogos