import React from 'react';
import Button from './Button';

const RightBrainText = () => {
  return (
    <div className='disc-list'>
      <h1 style={{ fontWeight: 'bold' }}>Data Science & Engineering</h1>
      <br />

      <p>
        We design and build modern data products and custom internal tools. Our
        data science, machine learning, and data visualization solutions are
        linked to measurable goals that help our clients achieve the competitive
        advantage.
      </p>

      <ul>
        <li>
          Audit for bias to process existing data sources, 3rd party sources,
          and raw data from IoT devices & sensors
        </li>
        <br />
        <li>
          Develop new data models or select from existing models specific to the
          problem you are trying to solve
        </li>
        <br />
        <li>
          Roll out models built during the project to empower operational
          decision
        </li>
      </ul>
      <br />
      <Button />
    </div>
  );
};

export default RightBrainText;
