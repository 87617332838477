import React from 'react'
import Banner from './Banner'
import Navbar from './Navbar'
import DoubleColTxtFirst from './DoubleColTxtFirst'
import SingleColCentered from './SingleColCentered'
import Divider from './Divider'
import Landing from './Landing'
import SlideLogos from './SlideLogos'
import CarouselTestimonials from './CarouselTestimonials'
import Footer from './Footer'
import DoubleColImgFirst from './DoubleColImgFirst'
import rightBrain from '../assets/byco-brand-right-brain-numb3rs-1280x720.png'
import RightBrainText from './RightBrainText'
import tinyDesk from '../assets/byco-brand-tiny-desk-leader-1280x720.png'
import TinyDeskText from './TinyDeskText'
import nextMove from '../assets/byco-brand-next-move-playbook-1280x720.png'
import NextMoveText from './NextMove'


const Home = () => {
  return (
    <div>
      {/* <Banner /> */}
      <Navbar />
      <Landing />
      <Divider/>
      <SlideLogos />
      <Divider />
      <SingleColCentered />
      <Divider/>
      <DoubleColImgFirst image={rightBrain} text={<RightBrainText />}/>
      <Divider/>
      <DoubleColTxtFirst image={nextMove} text={<NextMoveText/>}/>
      <Divider/>
      <DoubleColImgFirst image={tinyDesk} text={<TinyDeskText />} />
      <Divider/>
      <CarouselTestimonials />
      <Footer />

    </div>
  )
}

export default Home