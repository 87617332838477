import React from 'react';
import './FooterStyles.css';
import { Link } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  return (
    <footer>
      <nav className='footer-navbar'>
        <div className='copyright'>
          © 2023 BingYune & Company LLC. All Rights Reserved.
        </div>

        <ul className='footer-nav-menu'>
          <li className='footer-nav-item'>
            <Link to='/privacy' className='footer-nav-link'>
              {' '}
              Privacy{' '}
            </Link>
          </li>
          <li className='footer-nav-item'>
            <Link to='/terms' className='footer-nav-link'>
              {' '}
              Terms{' '}
            </Link>
          </li>
        </ul>
        {/* <div className='social-icons'>
          <div className='social-icon'>
            <Link to='/about'>
              <FontAwesomeIcon
                icon={faInfoCircle}
                style={{
                  paddingTop: '.2rem',
                  color: 'var(--white)',
                  height: 35,
                  width: 35,
                }}
              />
            </Link>
          </div>
          <div className='social-icon'>
            <SocialIcon
              url='https://www.linkedin.com/company/dataconcierge/?utm_source=byco_main&utm_medium=web_footer&utm_campaign=social_linkedin&utm_id=linkedin002&utm_content=homepage'
              bgColor='white'
              style={{ height: 35, width: 35 }}
            />
          </div>

          <div className='social-icon'>
            <SocialIcon
              url='https://medium.com/@codecaviar'
              bgColor='white'
              fgColor='var(--black)'
              style={{ height: 35, width: 35 }}
            />
          </div>

          <div className='social-icon'>
            <SocialIcon
              url='https://github.com/codecaviar?utm_source=byco_main&utm_medium=web_footer&utm_campaign=social_github&utm_id=github002&utm_content=homepage'
              bgColor='white'
              fgColor='var(--black)'
              style={{ height: 35, width: 35 }}
            />
          </div>
        </div> */}
      </nav>
    </footer>
  );
};

export default Footer;
