import React from 'react';
import Button from './Button';

const NextMoveText = () => {
  return (
    <div className='disc-list'>
      <h1 style={{ fontWeight: 'bold' }}>Data Strategy & Validation</h1>
      <br />
       
      <p>
        We collaborate with leaders to explore what's possible with data and aim
        to create a plan. We help define and solve their toughest and most
        complex business problems by bringing an approach to advice that
        combines deep industry knowledge, primary research, and advanced
        analytics to enable confident action.
      </p>

      <ul>
        <li>
          Assess data collection methods, industry regulations, and business
          objectives to meet your goals
        </li>
        <br />
        <li>
          Gather buy-in from executives and key stakeholders on a viable
          solution
        </li>
        <br />
        <li>
          Identify a low-cost way to test the strategy and run data experiments
        </li>
      </ul>
      <br />
      <Button />
    </div>
  );
};

export default NextMoveText;
