import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import bycLogo from '../assets/bingyune-and-company-logo-2880x640.png';
import { FaBars, FaTimes } from 'react-icons/fa';
import './NavbarStyles.css';
import { SocialIcon } from 'react-social-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  return (
    <div>
      <header>
        <nav className='navbar'>
          <div className='logo'>
            <Link to='/'>
              {' '}
              <img src={bycLogo} alt='BYC Logo' />{' '}
            </Link>
          </div>

          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item '>
              <Link to='/about' >
                <FontAwesomeIcon
                className='info-circle'
                  icon={faInfoCircle}
                  style={{  height: 35, width: 35 }}
                />
              </Link>
            </li>

            <li className='nav-item'>
              <SocialIcon
                url='https://www.linkedin.com/company/dataconcierge/?utm_source=byco_main&utm_medium=web_footer&utm_campaign=social_linkedin&utm_id=linkedin002&utm_content=homepage'
                bgColor='var(--black)'
                fgColor='var(--white'
                style={{ height: 35, width: 35 }}
              />
            </li>

            <li className='nav-item'>
              <SocialIcon
                url='https://medium.com/@codecaviar'
                bgColor='var(--black)'
                fgColor='var(--white)'
                style={{ height: 35, width: 35 }}
              />
            </li>

            <li className='nav-item'>
              <SocialIcon
                url='https://github.com/codecaviar?utm_source=byco_main&utm_medium=web_footer&utm_campaign=social_github&utm_id=github002&utm_content=homepage'
                bgColor='var(--black)'
                fgColor='var(--white)'
                style={{ height: 35, width: 35 }}
              />
            </li>
          </ul>

          {/* <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-link'>
                {' '}
                Home{' '}
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/about' className='nav-link'>
                {' '}
                About{' '}
              </Link>
            </li>
          </ul> */}

          <div className='hamburger' onClick={handleClick}>
            {click ? (
              <FaTimes size={25} style={{ color: 'black' }} />
            ) : (
              <FaBars size={25} style={{ color: 'black' }} />
            )}
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Navbar;
