import React from 'react';
import './SingleColCenteredStyles.css'

const SingleColCentered = () => {
  return (
    <div className='single-column'>
      <div className='column-centered'>
        <h1 style={{fontWeight: 'bold'}}>Automate Everything, Equip Everyone.</h1>
        <br />
        <p>
        BingYune & Co is an advanced analytics firm building with data at the intersection of strategy, technology, and inclusion. We develop the data products, insights, and habits that help organizations succeed above standard norms, consistently over the long run. Focusing on predictive analytics, intelligent automation, and natural language processing, we partner with organizations to innovate with AI, apply inclusive design principles, mitigate bias, automate processes, and be more cost-efficient. We achieve these results through our proprietary technology solutions, deep industry knowledge, and exceptional community care. We rally with leaders and teams to take collective, positive actions, so that their organizations become a driving force for social, environmental, and economic benefit in the world.
        </p>
      </div>
    </div>
  );
};

export default SingleColCentered;
