import React from 'react';
import './CarouselTestimonialsStyles.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import technologyTestimonial from '../assets/technology-testimonials-1280x1024.png';
import healthcareTestimonial from '../assets/healthcare-testimonials-1280x1024.png';
import educationTestimonial from '../assets/education-testimonials-1280x1024.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons'


function CarouselTestimonials() {
  return (
    <div className='testimonial-header'>
      
      <h1 style={{fontWeight: 'bold'}}>You'll be in great company </h1>

      <div className='testimonials'>
        <Carousel
          showArrows
          autoPlay
          infiniteLoop
          labels
          transitionTime='1500'
          interval='15000'
          showThumbs={false}
          showStatus={false}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            const defStyle = { fontWeight: 'bold', margin:15, color: "lightgrey", cursor: "pointer" };
            const style = isSelected
              ? { ...defStyle, color:"darkorange" }
              : { ...defStyle };
            return ( 
              <span
                style={style}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                aria-label={`${label} ${index + 1}`}
              > 
                {<FontAwesomeIcon icon={faCircle } />}
              </span>
            );
          }}
        >
          <div className='testimonial'>
            <img src={technologyTestimonial} alt='Technology Client Testimonials' />
          </div>
          <div className='testimonial'>
            <img src={healthcareTestimonial} alt='Healthcare Client Testimonials' />
          </div>
          <div className='testimonial'>
            <img src={educationTestimonial} alt='Education Client Testimonials' />
          </div>
        </Carousel>
      </div>
    </div>
  );
}

export default CarouselTestimonials;
