import React from 'react';
import './TermsStyles.css';
import Footer from './Footer';
import { Link } from 'react-router-dom';

const Terms = () => {
  return (
    <div>
      <div className='contact'>
        <h2 style={{ fontWeight: 'bold', fontSize: 26 }}> Terms </h2>

        <h1 style={{ fontWeight: 'bold', fontSize: 30 }}>TERMS OF USE</h1>

        <p>
          PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS WEBSITE. By
          using this website, you signify your consent to these terms of use. If
          you do not agree to these Terms of Use, please do not use the website.
        </p>
        <p>
          Your access to and use of this website, as well as all related
          websites operated by BingYune & Company LLC (which includes
          <Link to='/'> www.bingyune.com</Link>, among others)
          (collectively the “Site”) is subject to the following terms and
          conditions (“Terms of Use”) and all applicable laws. By accessing and
          browsing the Site, you accept, without limitation or qualification,
          the Terms of Use and acknowledge that any other agreements between you
          and the Site are superseded and of no force or effect:
        </p>
        <p>
          1. You agree that the Site itself, as well as all content, videos,
          training materials, products, services and/or other materials, made
          available on the Site by us or other third parties, as well as the
          look and feel of all of the foregoing, (collectively referred to as
          the “Content”) are maintained for your personal use and information by
          BingYune & Company LLC, (the “Company”) and are the property of the
          Company and/or its third party providers. You agree that such Company
          Content shall include all proprietary videos, HTML/CSS, Javascript,
          graphics, voice, and sound recordings, artwork, photos, documents, and
          text as well as all other materials included in the Site, excluding
          only the materials you provide. Subject to your compliance with these
          Terms of Use, the Company hereby grants you a limited license, which
          is non-exclusive, non-transferable, and non-sublicensable, to access,
          view, and use the Site solely for your personal purposes. No Company
          Content may be copied, reproduced, republished, uploaded, posted,
          transmitted, distributed, used for public or commercial purposes, or
          downloaded in any way unless written permission is expressly granted
          by the Company. Modification of the Content or use of the Content for
          any other purpose is a violation of the copyright and other
          proprietary rights of the Company, as well as other authors who
          created the materials, and may be subject to monetary damages and
          penalties. You may not distribute, modify, transmit or use the content
          of the Site or any Content, including any and all software, tools,
          graphics and/or sound files, for public or commercial purposes without
          the express written permission of the Company.
        </p>
        <p>
          2. All Content, such as text, data, graphics files, videos and sound
          files, and other materials contained in the Site, are copyrighted
          unless otherwise noted and are the property of the Company and/or a
          supplier to the Company. No such materials may be used except as
          provided in these Terms of Use.
        </p>
        <p>
          3. All trade names, trademarks, and images and biographical
          information of people used in the Company Content and contained in the
          Site, including without limitation the names and trademarks “BY&Co”,
          "P.O.W.E.R.", "tiny desk LEADER", "next move PLAYBOOK", and "right
          brain NUMB3RS" are either the property of, or used with permission by,
          the Company. The use of Content by you is strictly prohibited unless
          specifically permitted by these Terms of Use. Any unauthorized use of
          Content may violate the copyright, trademark, and other proprietary
          rights of the Company and/or third parties, as well as the laws of
          privacy and publicity, and other regulations and statutes. Nothing
          contained in this Agreement or in the Site shall be construed as
          granting, by implication or otherwise, any license or right to use any
          Trademark or other proprietary information without the express written
          consent of the Company or third party owner. The Company respects the
          copyright, trademark and all other intellectual property rights of
          others. The Company has the right, but has no obligation, to remove
          content and accounts containing materials that it deems, in its sole
          discretion, to be unlawful, offensive, threatening, libelous,
          defamatory, pornographic, obscene or otherwise objectionable or
          violates any party’s intellectual property or these Terms of Use. If
          you believe that your intellectual property rights are being violated
          and/or that any work belonging to you has been reproduced on the Site
          or in any Content in any way, you may notify Company at{' '}
          <span
            className='mailto'
            onClick={() => (window.location = 'mailto:support@bingyune.com')}
          >
            support@bingyune.com
          </span>. Please
          provide your name and contact information, the nature of your work and
          how it is being violated, all relevant copyright and/or trademark
          registration information, the location/URL of the violation, and any
          other information you believe is relevant.
        </p>
        <p>
          4. While the Company uses reasonable efforts to include accurate and
          up-to-date information in the Site, the Company makes no warranties or
          representations as to its accuracy. The Company assumes no liability
          or responsibility for any errors or omissions in the content of the
          Site.
        </p>
        <p>
          5. When you register with the Company and/or this Site, you expressly
          consent to receive any notices, announcements, agreements,
          disclosures, reports, documents, communications concerning new
          products or services, or other records or correspondence from the
          Company. You consent to receive notices electronically by way of
          transmitting the notice to you by email.
        </p>
        <p>
          6. If you send comments or suggestions about the Site to the Company,
          including, but not limited to, notes, text, drawings, images, designs
          or computer programs, such submissions shall become, and shall remain,
          the sole property of the Company. No submission shall be subject to
          any obligation of confidence on the part of the Company. The Company
          shall exclusively own all rights to (including intellectual property
          rights thereto), and shall be entitled to unrestricted use,
          publication, and dissemination as to all such submissions for any
          purpose, commercial or otherwise without any acknowledgment or
          compensation to you.
        </p>
        <p>
          7. The Company shall use commercially reasonable efforts to restrict
          unauthorized access to our data and files. However no system whether
          or not password protected can be entirely impenetrable. You
          acknowledge that it may be possible for an unauthorized third party to
          access, view, copy, modify, or distribute the data and files you store
          using the Site. Use of the Site is completely at your own risk.
        </p>
        <p>
          8. The Company will not intentionally disclose any personally
          identifying information about you to third parties, except where the
          Company, in good faith, believes such disclosure is necessary to
          comply with the law or enforce these Terms of Use. By using the Site,
          you signify your acceptance of the Company’s Privacy Policy,{' '}
          <Link to='/privacy'>
            www.bingyune.com/privacy
          </Link>.
        </p>
        <p>
          9. NEITHER THE COMPANY NOR ANY OTHER PARTY INVOLVED IN CREATING,
          PRODUCING, OR MAINTAINING THE SITE AND/OR ANY CONTENT ON THE SITE
          SHALL BE LIABLE UNDER ANY CIRCUMSTANCES FOR ANY DIRECT, INCIDENTAL,
          CONSEQUENTIAL, INDIRECT, OR PUNITIVE DAMAGES ARISING OUT OF YOUR
          ACCESS TO OR USE OF THE SITE. WITHOUT LIMITING THE FOREGOING, ALL
          CONTENT ON THE SITE IS PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND,
          EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
          IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR
          PURPOSE. THE COMPANY DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS
          REGARDING THE USE OF THE MATERIALS IN THE SITE, THE RESULTS OF THE USE
          OF SUCH MATERIALS, THE SUITABILITY OF SUCH MATERIALS FOR ANY USER’S
          NEEDS OR THE LIKELIHOOD THAT THEIR USE WILL MEET ANY USER’S
          EXPECTATIONS, OR THEIR CORRECTNESS, ACCURACY, RELIABILITY, OR
          CORRECTION. THE COMPANY LIKEWISE DOES NOT WARRANT OR MAKE ANY
          REPRESENTATIONS OR GUARANTEES THAT YOU WILL EARN ANY MONEY USING THE
          SITE OR THE COMPANY’S TECHNOLOGY OR SERVICES. YOU ACCEPT ALL
          RESPONSIBILITY FOR EVALUATING YOUR OWN EARNING POTENTIAL AS WELL AS
          EXECUTING YOUR OWN BUSINESS AND SERVICES. YOUR EARNING POTENTIAL IS
          ENTIRELY DEPENDENT ON YOUR OWN PRODUCTS, IDEAS, TECHNIQUES; YOUR
          EXECUTION OF YOUR BUSINESS PLAN; THE TIME YOU DEVOTE TO THE PROGRAM,
          IDEAS AND TECHNIQUES OFFERED AND UTILIZED; AS WELL AS YOUR FINANCES,
          YOUR KNOWLEDGE AND YOUR SKILL. SINCE THESE FACTORS DIFFER AMONG ALL
          INDIVIDUALS, THE COMPANY CANNOT AND DOES NOT WARRANT OR MAKE ANY
          REPRESENTATIONS OR GUARANTEES REGARDING YOUR SUCCESS OR INCOME LEVEL.
          THE COMPANY DOES NOT WARRANT THAT USE OF THE MATERIALS WILL BE
          UNINTERRUPTED OR ERROR FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT
          THIS SITE, THE CONTENT, AND/OR THE MATERIALS AVAILABLE ON THIS SITE
          ARE FREE FROM BUGS OR VIRUSES OR OTHER HARMFUL COMPONENTS. YOU ASSUME
          ALL RESPONSIBILITY FOR THE COST OF ALL NECESSARY REPAIRS OR
          CORRECTIONS. THE COMPANY SHALL NOT BE RESPONSIBLE FOR ANY PERFORMANCE
          OR SERVICE PROBLEMS CAUSED BY ANY THIRD PARTY WEBSITE OR THIRD PARTY
          SERVICE PROVIDER. ANY SUCH PROBLEM SHALL BE GOVERNED SOLELY BY THE
          AGREEMENT BETWEEN YOU AND THAT PROVIDER. Please note that the
          applicable jurisdiction may not allow the exclusion of implied
          warranties. Some of the above exclusions may thus not apply to you..
          If you do not agree with this Privacy Policy, in whole or part, please
          do not use this Site.
        </p>
        <p>
          10. THE COMPANY SHALL NOT BE RESPONSIBLE FOR ANY PERFORMANCE OR
          SERVICE PROBLEMS CAUSED BY ANY THIRD PARTY WEBSITE OR THIRD PARTY
          SERVICE PROVIDER (including, for example, your web service provider
          service, Stripe payment services, your software and/or any updates or
          upgrades to that software). ANY SUCH PROBLEM SHALL BE GOVERNED SOLELY
          BY THE AGREEMENT BETWEEN YOU AND THAT PROVIDER. THE COMPANY RESERVES
          THE RIGHT TO DETERMINE, IN ITS SOLE DISCRETION, WHETHER THE COMPANY IS
          RESPONSIBLE FOR ANY SUCH MALFUNCTION OR DISRUPTION. THE COMPANY ALSO
          RESERVES THE RIGHT TO LIMIT YOUR USE OF THE SITE AND/OR THE CONTENT OR
          TO TERMINATE YOUR ACCOUNT SHOULD THE COMPANY DETERMINE THAT YOU HAVE
          VIOLATED THESE TERMS OF USE, OR THAT YOU HAVE VIOLATED ANY OTHER RULES
          OR CONDITIONS OF THE COMPANY. THE COMPANY RESERVES THE RIGHT TO REFUSE
          ACCESS TO THE SITE AND/OR THE COMPANY’S CONTENT, PRODUCTS AND/OR
          SERVICES TO ANYONE IN ITS SOLE DISCRETION. THE COMPANY RESERVES THE
          RIGHT TO DETERMINE, IN ITS SOLE DISCRETION, WHETHER THE COMPANY IS
          RESPONSIBLE FOR ANY SUCH MALFUNCTION OR DISRUPTION. THE COMPANY MAY,
          IN ITS SOLE DISCRETION, REFUND THE INITIAL FEE CHARGED FOR ANY USE OF
          THE SITE AND/OR ANY CONTENT OR A PRO-RATA PORTION THEREOF CONSISTENT
          WITH THE COMPANY’S REFUND POLICY. THE COMPANY SHALL REFUSE ANY REFUND
          THIRTY (30) DAYS AFTER YOUR PAYMENT FOR USE OF THE SITE AND/OR ANY
          CONTENT, EITHER PURSUANT TO THE COMPANY’S CUSTOMER LICENSE AGREEMENT
          OR OTHERWISE, REGARDLESS OF THE REASON FOR DISRUPTION.
        </p>
        <p>
          11. IN NO EVENT SHALL THE COMPANY BE LIABLE FOR ANY SPECIAL,
          INCIDENTAL, INDIRECT, PUNITIVE, RELIANCE OR CONSEQUENTIAL DAMAGES,
          WHETHER FORESEEABLE OR NOT, INCLUDING, BUT NOT LIMITED TO, DAMAGE OR
          LOSS OF PROPERTY, EQUIPMENT, INFORMATION OR DATA, LOSS OF PROFITS,
          REVENUE OR GOODWILL, COST OF CAPITAL, COST OF REPLACEMENT SERVICES, OR
          CLAIMS FOR SERVICE INTERRUPTIONS OR TRANSMISSION PROBLEMS, OCCASIONED
          BY ANY DEFECT IN THE SITE, THE CONTENT, AND/OR RELATED MATERIALS, THE
          INABILITY TO USE SERVICES PROVIDED HEREUNDER OR ANY OTHER CAUSE
          WHATSOEVER WITH RESPECT THERETO, REGARDLESS OF THEORY OF LIABILITY.
          THIS LIMITATION WILL APPLY EVEN IF THE COMPANY HAS BEEN ADVISED OR IS
          AWARE OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>
        <p>
          12. You agree to indemnify and hold the Company and each of its
          directors, officers employees, and agents, harmless from any and all
          liabilities, claims, damages and expenses, including reasonable
          attorney’s fees, arising out of or relating to (i) your breach of this
          Agreement, (ii) any violation by you of law or the rights of any third
          party, (iii) any materials, information, works and/or other content of
          whatever nature or media that you post or share on or through the
          Site, (iv) your use of the Site or any services that the Company may
          provide via the Site, and (v) your conduct in connection with the Site
          or the services or with other users of the Site or the services. The
          Company reserves the right to assume the exclusive defense of any
          claim for which we are entitled to indemnification under this Section.
          In such event, you shall provide the Company with such cooperation as
          is reasonably requested by the Company.
        </p>
        <p>
          13. The provisions of these Terms of Use are for the benefit of the
          Company, its subsidiaries, affiliates and its third party content
          providers and licensors, and each shall have the right to assert and
          enforce such provisions directly or on its own behalf.
        </p>
        <p>
          14. This agreement shall be governed by and construed in accordance
          with the laws of the State of California, without giving effect to any
          principles of conflicts of law. You further submit to the exclusive
          jurisdiction of the state and federal courts sitting in Orange County,
          California. If any provision of this agreement shall be unlawful,
          void, or for any reason unenforceable, then that provision shall be
          deemed severable from this agreement and shall not affect the validity
          and enforceability of any remaining provisions.
        </p>
        <p>
          15. These Terms of Use may be revised from time to time by updating
          this posting. You are bound by any such revisions and should therefore
          periodically visit this page to review the then current Terms of Use
          to which you are bound.
        </p>
        <p>Last Updated: March 1, 2023</p>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
