import React from 'react';
import './DoubleColImgFirstStyles.css';
import 'bootstrap/dist/css/bootstrap.css';

const DoubleColTxtFirst = ({ image, text }) => {
  return (
    <div>
      <div className='container'>
        <div className='row'>
        <div className='col-sm-6' id='service-text'>
            {text}{' '}
          </div>
          <div className='col-sm-6' id='service-image'>
            <img src={image} alt='Service Offering Logo'></img>
          </div>

          
        </div>
      </div>
    </div>
  );
};

export default DoubleColTxtFirst;
