import React from 'react'
import Terms from '../components/Terms'
import Navbar from '../components/Navbar'

const TermsRoute = () => {
  return (
    <div>
      <Navbar />
      <Terms />

    </div>
  )
}

export default TermsRoute