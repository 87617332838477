import React from 'react'
import Privacy from '../components/Privacy'
import Navbar from '../components/Navbar'

const PrivacyRoute = () => {
  return (
    <div>
      <Navbar />
      <Privacy />

    </div>
  )
}

export default PrivacyRoute