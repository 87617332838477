import React from 'react';
import Button from './Button';

const TinyDeskText = () => {
  return (
    <div className='disc-list'>
      <h1 style={{ fontWeight: 'bold' }}>Data Culture & Leadership</h1>
      <br />
      
      <p>
        We boost the data literacy of your teams to ensure they can meaningfully
        contribute to the continuous improvement of your entire data process. We
        facilitate workshops, events, and experiences that enable your whole
        organization to work in better service of its strategic vision.
      </p>

      <ul>
        <li>
          Train non-technical employees to adapt to new tools such as Python,
          SQL, and Apache Spark
        </li>
        <br />
        <li>
          Build data governance and IT infrastructure to ensure that
          organizations can have lasting performance improvement
        </li>
        <br />
        <li>
          Support technical managers with frameworks on remote workforce
          communication, 1-on-1 feedback, and performance reviews
        </li>
      </ul>
      <br />
      <Button />
    </div>
  );
};

export default TinyDeskText;
