import React from 'react';
import './PrivacyStyles.css';
import Footer from './Footer';
import PrivacyImage from '../assets/privacy_table.png'
import { Link } from 'react-router-dom';


const Privacy = () => {
  return (
    <div>
      <div className='contact'>
        <h2 style={{ fontWeight: 'bold', fontSize: 26 }}> Privacy Policy </h2>

        <h1 style={{ fontWeight: 'bold', fontSize: 30 }}>
          Online Privacy Policy
        </h1>

        <p>
          BingYune & Company LLC (the “Company”) respects the privacy concerns
          of the users of its website,{' '}
          <Link to='/'>
            www.bingyune.com
          </Link> and the services
          provided therein (the “Site”). The Company thus provides this privacy
          statement to explain what information is gathered during a visit to
          the Site and how such information may be used.
        </p>

        <p>
          Please also review the Terms of Use at{' '}
          <Link to='/terms'>
            www.bingyune.com/terms
          </Link>
        </p>

        <p>
          <strong>Use of Information: </strong> As a general policy, no
          personally identifiable information, such as your name, address, or
          e-mail address, is automatically collected from your visit to the
          Site. However, certain non-personal information is recorded by the
          standard operation of the Company’s internet servers. Information such
          as the type of browser being used, its operating system, and your IP
          address is gathered in order to enhance your online experience.which
          also govern use of this Site.
        </p>

        <p>
          The Site’s various mailing lists, downloads, special offers, contests,
          registration forms, and surveys may request that you give us contact
          information such as your name, mailing and/or e-mail address,
          demographic information such as your age and gender, and personal
          preference information such as your preferred software and interests.
          Information submitted at the time of submission will be used by the
          Company only as necessary for our legitimate business interests,
          including without limitation the improvement of our products, services
          and the contents of the Site. The Company may also share such
          information with our business and promotional partners to further
          those interests. Personally identifiable information is never sold or
          leased to any third parties. With your permission, we may use your
          contact information to send you information about our company and
          products. You may always opt-out of receiving future mailings as
          provided below. The Company does not store any credit card information
          it may receive in regard to a specific transaction and/or billing
          arrangement except as necessary to complete and satisfy its rights and
          obligations with regard to such transaction, billing arrangement,
          and/or as otherwise authorized by a user.
        </p>

        <p>
          The Company may disclose user information in special cases when we
          have reason to believe that disclosing this information is necessary
          to identify, contact or bring legal action against someone who may be
          causing injury to or interference (either intentionally or
          unintentionally) with the Company’s rights or property, other users of
          the Site, or anyone else that could be harmed by such activities.
        </p>

        <p>
          <strong> Children Age 13 and Under:</strong> The Company recognizes
          the special obligation to protect personally identifiable information
          obtained from children age 13 and under. AS SUCH, IF YOU ARE 13 YEARS
          OLD OR YOUNGER, THE COMPANY REQUESTS THAT YOU NOT SUBMIT ANY PERSONAL
          INFORMATION TO THE SITE OR TO THE COMPANY. If the Company discovers
          that a child age 13 or younger has signed up on the Site or provided
          us with personally identifiable information, we will delete that
          child’s identifiable information from our records.
        </p>

        <p>
          The Company nonetheless encourages parents to go online with their
          kids. Here are a few tips to help make a child’s online experience
          safer:
        </p>
        <p>
          1. Teach kids never to give personal information, unless supervised by
          a parent or responsible adult. Includes name, address, phone, school,
          etc.
        </p>
        <p>
          2. Know the sites your kids are visiting and which sites are
          appropriate.
        </p>
        <p>
          3. Look for Website privacy policies. Know how your child’s
          information is treated.
        </p>
        <p>
          4. Check out the FTC’s site for more tips on protecting children's
          privacy online
        </p>
        <p>
          <strong> Use of Cookies:</strong> Cookies are pieces of information
          that a website transfers to an individual’s computer hard drive for
          record keeping purposes. Cookies make using our Site easier by, among
          other things, saving your passwords and preferences for you. These
          cookies are restricted for use only on our Site, and do not transfer
          any personal information to any other party. Most browsers are
          initially set up to accept cookies. You can, however, reset your
          browser to refuse all cookies or indicate when a cookie is being sent.
          Please consult the technical information relevant to your browser for
          instructions. If you choose to disable your cookies setting or refuse
          to accept a cookie, some parts of the Site may not function properly
          or may be considerably slower.
        </p>
        <p>
          <strong> Malware/Spyware/Viruses:</strong> Neither the Company nor the
          Site knowingly permit the use of malware, spyware, viruses, and/or
          other similar types of software.
        </p>
        <p>
          <strong> Links to External Sites: </strong>The Company is not
          responsible for the content or practices of third party websites that
          may be linked to the Site. The Company is also not responsible for any
          information that you might share with such linked websites. You should
          refer to each website’s respective privacy policy and practices prior
          to disclosing any information.
        </p>
        <p>
          <strong> Bulletin Boards and Chat Areas:</strong> Guests of the Site
          are solely responsible for the content of messages they post on the
          Company’s forums, such as chat rooms and bulletin boards. Users should
          be aware that when they voluntarily disclose personal information
          (e.g., user name, e-mail address, phone number) on the bulletin boards
          or in the chat areas, that information can be collected and used by
          others and may result in unsolicited messages from other people. You
          are responsible for the personal information you choose to submit in
          these instances. Please take care when using these features.
          Choice/Opt-Out: The Site may provide you the opportunity to opt-in to
          receive communications from us at the point where we request
          information about you. You always have the option of removing your
          name from any e-mail list in order to discontinue any such future
          communications. In order to ensure immediate removal from any list,
          please follow the specific instructions set forth within the
          communications you receive from the Company which you no longer wish
          to receive. If you are unsuccessful in completing the instructions
          specified in any such communication, please e-mail us at{' '}
          <span
            className='mailto'
            onClick={() => (window.location = 'mailto:support@bingyune.com')}
          >
            support@bingyune.com
          </span>
          , including a copy of the undesired email attached to the request, and
          state you wish to be removed from the mailing list.
        </p>
        <p>
          <strong> Contact Information for Complaints or Concerns:</strong> If
          you have any complaints or concerns about the Company or about this
          privacy statement, please contact:
        </p>
        <p>
          Via email:{' '}
          <span
            className='mailto'
            onClick={() => (window.location = 'mailto:support@bingyune.com')}
          >
            support@bingyune.com
          </span>
        </p>
        <p>or</p>
        <p>
          Via regular mail: BingYune & Company LLC; 95 3rd Street,
          San Francisco CA 94103
        </p>
        <p>
          Information provided by you via general e-mail inquiries to the
          Company such as your e-mail address is used only to respond to your
          inquiries in the ordinary course of business, and is never shared with
          third parties.
        </p>
        <p>
          <strong> Security: </strong>Security for all personally identifiable
          information is extremely important to us. Unfortunately, no data
          transmission over the internet can be guaranteed to be 100% secure. As
          a result, while we strive to protect your personal information, The
          Company cannot ensure or warrant the security of any information you
          transmit via the internet. By transmitting any such information to the
          Company, you accept that you do so at your own risk.
        </p>
        <p>
          <strong> Your Acceptance of These Terms: </strong>By using the Site,
          you accept the policies and restrictions set forth in this Online
          Privacy Policy. If you do not agree to this policy, please do not use
          the Site. This Online Privacy Policy may be revised from time to time
          by updating this posting. You are bound by any such revisions and
          should therefore periodically visit this page to review the then
          current Online Privacy Policy to which you are bound.
        </p>
        <p>Last Updated: March 1, 2023</p>
        <p></p>

              </div>
      <Footer />
    </div>
  );
};

export default Privacy;
