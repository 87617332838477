import React from 'react'
import About from '../components/About'
import Navbar from '../components/Navbar'

const AboutRoute = () => {
  return (
    <div>
      <Navbar />
      <About />

    </div>
  )
}

export default AboutRoute