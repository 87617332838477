import React from 'react';
import { Navigate, Routes, Route} from 'react-router-dom';
import Home from './components/Home';
import AboutRoute from './routes/AboutRoute';
import ContactRoute from './routes/ContactRoute';
import PrivacyRoute from './routes/PrivacyRoute';
import TermsRoute from './routes/TermsRoute';

function App() {
  return (
    <div>
      <Routes>
        
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<AboutRoute />} />
        <Route path='/privacy' element={<PrivacyRoute  />} />
        <Route path='/terms' element={<TermsRoute  />} />
        <Route path='*' element={<Navigate to='/'  />} />

      </Routes>

    </div>
  );
}

export default App;
