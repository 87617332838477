import React from 'react';
import './AboutStyling.css';
import Footer from './Footer';

const About = () => {
  return (
    <div>
      <div className='about-background'>
        <div className='disc-list'>
          <h2 style={{ fontWeight: 'bold', fontSize: 26 }}>About</h2>

          <h1 style={{ fontWeight: 'bold', fontSize: 30 }}>
            what is BingYune & Co's story?
          </h1>

          <p>
            BingYune & Co is an advanced analytics firm building with data at
            the intersection of strategy, technology, and inclusion. We develop
            the data products, insights, and habits that help organizations
            succeed above standard norms, consistently over the long run.
          </p>

          <p>
            Focusing on predictive analytics, intelligent automation, and
            natural language processing, we partner with organizations to
            innovate with AI, apply inclusive design principles, mitigate bias,
            automate processes, and be more cost-efficient. We achieve these
            results through our proprietary technology solutions, deep industry
            knowledge, and exceptional community care.
          </p>

          <p>
            {' '}
            We rally with leaders and teams to take collective, positive
            actions, so that their organizations become a driving force for
            social, environmental, and economic benefit.
          </p>

          <p>Our office is located in San Francisco, California. </p>

          <hr />
          <h1 style={{ fontWeight: 'bold', fontSize: 30 }}>
            what is BingYune & Co's mission?
          </h1>

          <p>
            Our mission is to advance humanity by investing financial and social
            capital to build diverse and inclusive artificial intelligence.
          </p>
          <p>
            Companies of all sizes recognize the tremendous potential for
            artificial intelligence (AI) - that AI is no longer just a place to
            find answers, but it's also a place where ideas originate. In
            principle (and increasingly in practice), AI can help reduce the
            impact of humans' subjective interpretation of data and improve
            decision making. However, many companies still overlook the
            prevalence of bias by baking in and deploying biases at scale in
            harmful application areas. This "old world" approach to AI does not
            consistently create community safeguards and can perpetuate harmful
            forms of discrimination such as racism, sexism, and ableism.
          </p>
          <p>
            Fortunately, every employee, regardless of their current technical
            knowledge or training, can become a "hacktivist" to vet the cultural
            inclusivity and sensitivity of the algorithms for various groups.
            Running algorithms alongside human decision makers, comparing
            results, and using meaningful transparency to help pinpoint model
            decisions is the "new age" of AI. A diverse AI community, as part of
            this type of "human-in-the-loop" AI system, would be better equipped
            to anticipate, review, and spot biases and engage communities
            affected. In order to turn AI into a more equitable tool, there is a
            need for a collaborative toolkit to support continuous oversight and
            accountability.
          </p>

          <p>
            With this realization in mind, we built this non-technical toolkit
            for an increasingly broad audience - people on teams from Finance to
            Operations, Marketing to Engineering, and everything in between. We
            organized this toolkit around five core practices:{' '}
          </p>

          <ul>
            <li>
              Commit to improving the human-driven processes underlying
              perpetual biases identified in algorithms
            </li>
            <li>
              Maximize openness (code and intent) with internal partners,
              customers, and civil society organizations
            </li>
            <li>
              Apply fairness toolkits and explainability techniques to evaluate
              the training data and predictions
            </li>
            <li>
              Collaborate with design teams representative enough to understand
              with different culture contexts
            </li>
            <li>
              Prioritize feedback loops for developers, stakeholders, and
              communities to share impact
            </li>
          </ul>

          <p>
            P.O.W.E.R. Up<sup>TM</sup> is an online, freely revealed knowledge base of
            proven, integrated tools, insights, and habits that make the biggest
            difference when mitigating the harms and biases of AI. It provides
            comprehensive guidance for both motivation and capability at the
            three key levels of any organization: Intentional Individual,
            Dynamic Duo, and Transformational Team.
          </p>
          <p>
            Our commitment is to continuously create massive value for the
            organizations and communities we serve - faster tools, easier
            insights, and happier people - and only you, the adopters,
            practitioners, and leaders, can tell us whether or not we have
            accomplished our ongoing mission.
          </p>
          <hr />
          <h1 style={{ fontWeight: 'bold', fontSize: 30 }}>
            what are BingYune & Co's core values?
          </h1>

          <p>
            Our five core values embody our culture, spirit and dedication to
            being happy and doing what’s right. They keep us aligned and help us
            make decisions about everything from the data science solutions we
            offer to the way we design our consulting services - and we’ve been
            using them since day one.
          </p>

          <h3 style={{ fontWeight: 'bold', fontSize: 22 }}>
            live in possibility
          </h3>
          <p>
            create solutions where the company wins, the customer wins, the
            community wins
          </p>

          <h3 style={{ fontWeight: 'bold', fontSize: 22 }}>be in ownership</h3>
          <p>make sustainable decisions that will stand the test of time</p>
          <h3 style={{ fontWeight: 'bold', fontSize: 22 }}>
            focus on wellness
          </h3>
          <p>cultivate authentic products, services, and relationships</p>

          <h3 style={{ fontWeight: 'bold', fontSize: 22 }}>
            have more empathy
          </h3>
          <p>
            create meaningful connections every day to be a better leader,
            partner, and global citizen
          </p>

          <h3 style={{ fontWeight: 'bold', fontSize: 22 }}>based on results</h3>
          <p>think smarter, work harder, do it together</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
