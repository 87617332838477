import React from 'react';
import './ButtonStyles.css';


const Button = ({ buttonText='CLICK to Learn More' }) => {
  return (
    <div>
      <button
        className='button'
        onClick={() => (window.location = 'mailto:support@bingyune.com')}
      >
        {buttonText}{' '}
      </button>
    </div>
  );
};

export default Button;
